import { getShopCategoriesSetup, getShopCategoriesJson, deleteShopCategoriesId } from '@/services/product'
import * as shopCategoriesApi from '@/services/shop-categories.js'
import searchForm from '@/components/searchForm'
import allButton from '@/components/allButton'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
import jsonData from './jsonData.js'
import loading from '@/utils/loading.json'
export default {
  name: 'managerProduct',
  components: {
    searchForm,
    createTable,
    allButton
  },
  mixins: [mixin],
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  created () {
    this.queryData(getShopCategoriesSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getShopCategoriesSetup)
    this.isActive = true
  },
  methods: {
    async forwardToPage (data, name) {
      if (name === 'shop') {
        this.$router.push({
          name: 'shopsManagerDetail',
          params: {
            id: data.id
          }
        })
      }
    },
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = _this.$loading(loading) : _this.$store.state.app.loading.close()
    },
    // 排序
    handleSort (val) {
      this.$refs.searchForm.onSubmit(val)
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getShopCategoriesJson(this.searchData).then(res => {
        if (res.data) {
          if (res.data) {
            this.tableData = []
            this.$nextTick(() => {
              this.tableData = res.data.objects
              this.tabData = res.data.scopes
              Object.assign(this.pageData, res.data.pagination)
            })
          }
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'goodsManagerCategoriesAdd'
        })
      }
      if (type?.action === 'view') {
        // let obj = {}
        // this.$store.state.app.loading = this.$loading(loading)
        // const res = await getShopCategoriesId(data.id.value)
        // if (res.status === 200) {
        //   obj = res.data.shop_category
        // }
        // this.$store.state.app.loading.close()
        // this.$message({
        //   type: 'success',
        //   message: this.$t('message.loadSuccess')
        // })
        // sessionStorage.setItem('dataOperate', JSON.stringify({ id: data && data.id.value ? data.id.value : undefined, type: type.action, obj, data }))
        this.$router.push({
          name: 'goodsManagerCategoriesDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'goodsManagerCategoriesEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteShopCategoriesId)
      }
    },
    // 切换伸缩框
    handleChange (val) {
      // console.log(val)
    },
    // 删除数据
    // deleteClick (data) {
    //   // mock操作
    //   this.$confirm('是否删除该数据?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$store.state.app.loading = this.$loading(loading)
    //     deleteShopCategoriesId(data.id.value).then(res => {
    //       this.$store.state.app.loading.close()
    //       this.$message({
    //         type: 'success',
    //         message: '删除成功!'
    //       })
    //       this.search()
    //     }).catch(() => this.$store.state.app.loading.close())
    //   }).catch(() => {
    //   })
    // },
    // 数据的选择
    selectionChange (val) {
      this.selectionData = val
    },
    handleCreateTableSwitchChange (data) {
      const _this = this
      const { rowData, jsonItemData } = data
      if (jsonItemData.prop === 'online') {
        if (rowData[jsonItemData.prop].value) {
          _this.setGlobalLoading(true)
          shopCategoriesApi.postIdOnline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        } else {
          _this.setGlobalLoading(true)
          shopCategoriesApi.postIdOffline(rowData.id.value).then(() => {
            _this.setGlobalLoading(false)
            _this.$message({
              type: 'success',
              message: _this.$t('message.operateSuccess')
            })
            _this.search()
          }).catch(() => {
            _this.setGlobalLoading(false)
            _this.search()
          })
        }
      }
    }
  }
}
